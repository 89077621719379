<main>
    <app-banner></app-banner>
    <app-about></app-about>
    <app-researchareas></app-researchareas>
    <app-people></app-people>
    <app-projects></app-projects>
    <app-publications></app-publications>
    <app-theses></app-theses>
    <app-contact></app-contact>
</main>

