<section class="section contact" id='contact'>
    <div class="container">
        <div class="section-box">
            <div class="mb-4" data-aos="fade-up" data-aos-duration="1000">
                <h4 class="e-font contact-title big-title">
                    {{"Contact.Title" | translate}}
                </h4>
            </div>
            <p data-aos="fade-up" data-aos-duration="1000">
                <a href="mailto:sgulmez2018@gtu.edu.tr"> {{"email" | translate}}: sgulmez2018@gtu.edu.tr</a>
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
                <a href="tel:+902626052232"> {{"phone" | translate}}: +90 262 605 22 32</a>
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
                <a href="https://maps.app.goo.gl/T93bvLi55DpwiftXA">{{"address" | translate}}: {{"gtuaddress" | translate}}</a>
            </p>
            
            <div class="mt-5">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d897.8311997977855!2d29.357270069834584!3d40.80748476226609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cade5b10c31be7%3A0xba23b0e884a7e655!2sGebze%20Technical%20University%20Computer%20Engineering!5e0!3m2!1str!2str!4v1707737781791!5m2!1str!2str" width="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</section>