<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <li>
            </li>
            <li>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            
        </div>
    </div>

    <div class="footer-credits text-center" >
        <a href="https://github.com/sibelgulmez/nislab" target="_blank" rel="nofollow noopener noreferrer">
            <div>© 2024 Designed by Sibel GULMEZ</div>
        </a>
    </div>

</footer>