import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-researchareas',
  templateUrl: './researchareas.component.html',
  styleUrls: ['./researchareas.component.scss']
})
export class researchareasComponent implements OnInit {
  
  active = 0
  
  constructor(
  ) { }

  ngOnInit(): void {
  }

}
