<section class="section banner" id = "banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div class='banner-title'>
                    <h2>Nislab</h2>
                    <h3>{{'NislabLong' | translate}}</h3>
                </div>
            </div>
            <div class="div-btn-banner">
                <p>{{"Description" | translate}} </p>
                <br>
                <a href="mailto:sgulmez2018@gtu.edu.tr" target="_black" class="main-btn">
                    {{"GetInTouch" | translate}}
                </a>
            </div>
        </div>
    </div>
</section>
