<section class="section researchareas" id='researchareas'>
    <div class="container">
        <div class="section-box">
            <div class="about-title mb-5" data-aos="fade-up" data-aos-duration="1000">
                <h3 class="e-font section-title">
                    {{"ResearchAreas.Title" | translate}}
                </h3>
                <p>{{"ResearchAreas.Info" | translate}}</p>
                <ul class="skills-list" data-aos="fade-up">
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area1" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area2" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area3" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area4" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area5" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area6" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area7" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area8" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area9" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area10" | translate}} </span></li>
                    <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">{{"ResearchAreas.Area11" | translate}} </span></li>
                </ul>
            </div>
        </div>
    </div>
</section>
